<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <q-card flat bordered>
        <q-card-section>
          <base-input-text v-bind="formInputProps('label')" :color="color"
            v-model="formData.label" ref="label" />
        </q-card-section>

        <q-card-section class="row q-gutter-md items-end">
          <base-input-select v-bind="formInputProps('type_id')" :color="color" class="col"
            v-model="formData.type_id" ref="type_id" />

          <base-input-date v-bind="formInputProps('date_limite')" :color="color" class="col"
            v-model="formData.date_limite" ref="date_limite" />
        </q-card-section>

        <q-card-section>
          <base-input-text v-bind="formInputProps('description')" :color="color"
            v-model="formData.description" ref="description" />
        </q-card-section>

        <q-card-section v-if="formFields.some(({ id }) => id === 'compte_rendu')">
          <base-input-text v-bind="formInputProps('compte_rendu')" :color="color"
            v-model="formData.compte_rendu" ref="compte_rendu" />
        </q-card-section>
      </q-card>

      <h6>{{$t('sujets.related_objects')}}</h6>

      <div class="row q-py-md q-col-gutter-md">
        <div class="col col-12" v-for="obj in relatedObjects" :key="obj.id">
          <base-card>
            <q-avatar slot='avatar' size="3rem" color="reseau" text-color="white" :icon="obj.icon" />
            <q-item-label class="q-mb-md"><strong>{{obj.label}}</strong></q-item-label>
          </base-card>
        </div>
      </div>

      <div v-if="!isObjectsFormOpen" class="row">
        <base-add-button :label="$t('sujets.assign_object')" :color="color" @click="isObjectsFormOpen = true" />
      </div>

      <div v-else class="row">
        <div class="col col-6">
          <search-box ref="searchBox" v-model="linkedObjModel" :color="color" :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true }" />
        </div>
      </div>

      <h6>{{$t('sujets.related_links')}}</h6>

      <div class="row q-py-md q-col-gutter-md">
        <div class="col col-12" v-for="relatedLink in relatedLinks" :key="relatedLink.id">
          <base-card @menuClick="menuClick" :actions="relatedLink.actions">
            <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="link" />
            <q-item-label class="q-mb-md"><strong>{{relatedLink.titre}}</strong></q-item-label>
            <a :href="relatedLink.lien" target="_blank"><q-item-label :class="`text-${color}`">{{relatedLink.lien}}</q-item-label></a>
            <!-- <q-item-label :class="`text-${color}`" @click="openLink(relatedLink.lien)">{{relatedLink.lien}}</q-item-label> -->
          </base-card>
        </div>
      </div>

      <div v-if="!isLinksFormOpen" class="row">
        <base-add-button :label="$t('sujets.add_link')" :color="color" @click="isLinksFormOpen = true" />
      </div>

      <div v-else class="row">
        <q-card flat bordered class="q-pa-md col col-6">
          <base-input-text v-bind="titleTemp" color="per"
              v-model="link.titre" ref="admin_note" />
          <base-input-text v-bind="linkTemp" color="per"
              v-model="link.lien" ref="admin_note" />
          <q-btn :color="color" size="sm" class="float-right" unelevated @click="addLink()">{{$t('form.ok')}}</q-btn>
        </q-card>
      </div>

      <h6>{{$t('sujets.related_cibles')}}</h6>

      <div class="row q-py-md q-col-gutter-md">
        <div class="col col-12" v-for="(cible, index) in relatedCibles" :key="cible.id">
          <CibleCard :cible="cible" v-model="ciblesStatuts[index]" v-if="sujet && sujet.statut === 'AFFECTE'" />
          <CibleCard :cible="cible" v-else />
        </div>
      </div>

      <div v-if="!isCiblesFormOpen" class="row">
        <base-add-button :label="$t('sujets.assign_cible')" :color="color" @click="isCiblesFormOpen = true" />
      </div>

      <div v-else class="row">
        <div class="col col-6">
          <search-box ref="searchBox" model_type="groupeCible" @input="onCibleSelect" :color="color" :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true }" />
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
        </q-item>

        <q-item class="q-pa-none">
          <base-input-boolean v-bind="formInputProps('impact_per')" :color="color"
            v-model="formData.impact_per" ref="impact_per" />
        </q-item>

        <q-item class="q-pa-none">
          <base-input-boolean v-bind="formInputProps('visibilite')" :color="color"
            v-model="formData.visibilite" ref="visibilite" />
        </q-item>

        <q-item class="q-pa-none" v-if="hasRegionInput">
          <base-input-select v-bind="formInputProps('region_id')" :color="color"
            v-model="formData.region_id" ref="region_id" />
        </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" outline @click="$router.go(-1)">{{$t('form.cancel')}}</q-btn>
        <q-btn v-if="isDeletable" color="negative" class="full-width" size="sm" unelevated icon-right="delete"
          @click.native="destroy">{{$t('sujets.delete_sujet')}}</q-btn>
        <q-btn v-if="sujet && sujet.statut === 'AFFECTE'" :color="color" class="full-width q-pa-sm" size="md" @click="traiterSujet">{{$t('sujets.traiter_sujet')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../mixins'
import { CibleCard } from '../../components/cibles'
import { getLinkedObjectsFromModel } from '../../boot/helpers'

export default {
  name: 'SujetDetails',
  mixins: [BaseForm],
  components: { CibleCard },
  props: {
    'form_model': { type: String, default: 'sujets' },
    'action': { type: String, default: 'sujets/saveSujet' }
  },

  data () {
    return {
      isObjectsFormOpen: false,
      isLinksFormOpen: false,
      relatedObjects: [],
      relatedLinks: [],
      isCiblesFormOpen: false,
      relatedCibles: [],
      linkedObjModel: null,
      ciblesStatuts: [],
      titleTemp: { label: 'Titre du lien' }, // TO DO: change to back-end param, DELETE
      linkTemp: { label: 'Chemin réseau' }, // TO DO: change to back-end param, DELETE
      link: {
        titre: '',
        lien: ''
      }
    }
  },

  computed: {
    ...mapGetters({
      fromName: 'pages/getFromName',
      fromParams: 'pages/getFromParams',
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      isRPPR: 'auth/isRPPR',
      currentUser: 'auth/getCurrentUser',
      sujet: 'sujets/getCurrentSujet'
    }),

    hasRegionInput () {
      return this.isAdmin && this.formFields && this.formFields.some(({ id }) => id === 'region_id')
    },

    isAuthor () {
      return this.sujet && this.currentUser && this.currentUser.id === this.sujet.auteur
    },

    isDeletable () {
      return this.sujet && (this.isAdmin || this.isRPPR || (this.isAuthor && this.sujet.statut === 'BROUILLON'))
    }
  },

  watch: {
    relatedObjects (newVal, oldVal) {
      const objs = newVal.length > 0 ? newVal.map(({ model_type, model_id }) => ({ model_type, model_id })) : []
      const cibles = this.relatedCibles.length > 0 ? this.relatedCibles.map(({ id }) => ({ model_type: 'groupeCible', model_id: id })) : []
      this.formData.related_objects = [].concat(objs).concat(cibles)
      // console.log(this.formData)
    },

    relatedLinks (newVal, oldVal) {
      this.formData.lien_reseau = newVal.length > 0 ? newVal.filter(lien => !lien.id) : []
      console.log(this.formData)
    },

    relatedCibles (newVal, oldVal) {
      const objs = this.relatedObjects.length > 0 ? this.relatedObjects.map(({ model_type, model_id }) => ({ model_type, model_id })) : []
      const cibles = newVal.length > 0 ? newVal.map(({ id }) => ({ model_type: 'groupeCible', model_id: id })) : []
      this.formData.related_objects = [].concat(objs).concat(cibles)
      // console.log(this.formData)
    },

    formFields: {
      handler (newVal, oldVal) {
        if (!newVal) return
        if (!this.sujet) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options }) => ({ id, options }))
            .reduce((acc, { id, options }) => ({
              ...acc, [id]: options ? options.find(({ value }) => value === this.sujet[id]) : this.sujet[id]
            }), {})
        }

        if (typeof this.formData['region_id'] === 'object') {
          this.formData['region_id'] = this.formData['region_id'].value
        }

        const linked_objs = getLinkedObjectsFromModel(this.sujet)
        this.relatedObjects = linked_objs.map(linked_obj => ({ ...linked_obj.obj, model_type: linked_obj.type, model_id: linked_obj.obj.id }))
        this.relatedCibles = this.sujet['groupe_cibles']
        this.relatedLinks = this.sujet['liens']
        this.relatedLinks = this.relatedLinks.map(relatedLink => {
          return {
            ...relatedLink,
            actions: [
              { label: this.$t('sujets.delete_linked_link'), payload: { name: 'delete-link', params: { id: relatedLink.id } } }
            ]
          }
        })

        const getValueFromId = id => {
          if (this.sujet['cibles_validees'].indexOf(id) >= 0) return 'VALIDE'
          else if (this.sujet['cibles_rejetees'].indexOf(id) >= 0) return 'REJETE'
          return null
        }

        this.ciblesStatuts = this.relatedCibles.map(({ id }) => ({ id, value: getValueFromId(id) }))
      },
      immediate: true
    },

    ciblesStatuts: {
      handler (newVal, oldVal) {
        this.formData['cibles_validees'] = newVal.filter(({ value }) => value === 'VALIDE').map(({ id }) => id)
        this.formData['cibles_rejetees'] = newVal.filter(({ value }) => value === 'REJETE').map(({ id }) => id)
      },
      deep: true
    },

    linkedObjModel: {
      handler (newVal, oldVal) {
        const { type, value } = newVal
        if (value) {
          const { label, value: id } = value
          let icon, actions = [
            { label: this.$t('sujets.remove_linked_object'), payload: { name: 'sujet-detach', params: { id } } }
          ]

          if (type === 'agent') {
            icon = 'person'
          } else if (type === 'wallet') {
            icon = 'account_balance_wallet'
          } else if (type === 'entity') {
            icon = 'account_balance_wallet'
          }

          this.relatedObjects.push({ model_type: type, model_id: id, label, icon, actions })
          this.formData.related_objects = this.relatedCibles.length > 0 ? this.relatedCibles.map(
            ({ model_type, model_id }) => ({ model_type, model_id })
          ) : []
          this.isObjectsFormOpen = false
          this.$refs['searchBox'].reset()
        }
      },
      deep: true
    }
  },

  mounted () {
    this.fetchFormFields()
  },

  methods: {
    fetchFormFields () {
      if (this.$route.params.id) {
        this.formData.id = this.$route.params.id
        this.$store.dispatch('sujets/getSujet', this.$route.params.id)
          .then(() => this.$store.dispatch('sujets/fetchFormFields', { droits_title: this.sujet.statut }))
      } else {
        this.$store.commit('pages/setPageTitle', this.$t('sujets.add_sujet'))
        this.$store.dispatch('sujets/fetchFormFields')
      }
    },

    addLink () {
      if (this.link.titre === '' || this.link.lien === '') return
      if (!this.relatedLinks) this.relatedLinks = []

      // Remove " first before adding
      let copiedObject = Object.assign({}, this.link)
      copiedObject.lien = copiedObject.lien.replace(/"/g, '')

      this.relatedLinks.push(copiedObject)
      this.link.titre = ''
      this.link.lien = ''
      this.isLinksFormOpen = false
    },

    openLink (link) {
      console.log(link)
      window.open(`file:///${link}`, '_blank')
    },

    menuClick ({ name, params }) {
      if (name === 'delete-link') this.deleteLink(params.id)
    },

    deleteLink (linkId) {
      this.$confirm(this.$t('sujets.delete_link_confirm'), this.$t('form.delete')).onOk(() => {
        this.$store.dispatch('sujets/deleteLink', linkId).then(() => {
          this.$q.notify({ message: this.$t(`sujets.delete_link_success`), color: 'positive' })
          this.fetchFormFields()
        }).catch(this.$notifyError)
          .finally(() => { this.$emit('updated') })
      })
    },

    onCibleSelect (result) {
      const cible = result.value
      this.relatedCibles.push({ model_type: 'groupeCible', model_id: cible.id, ...cible })
      this.ciblesStatuts.push({ id: cible.id, value: null })
      this.isCiblesFormOpen = false
    },

    traiterSujet () {
      this.formData.statut = { label: 'TRAITE', value: 'TRAITE' }
      this.save()
    },

    onSave ({ id }) {
      this.$router.go(-1)
    },

    destroy () {
      this.$confirm(this.$t('sujets.delete_sujet_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('sujets/deleteSujet', this.$route.params.id).then(() => {
          this.notifySuccess('sujets.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
